.our-features{
    .our-feature{
        .list-group{
            .list-group-item{
                border-radius: 4px;
                box-shadow: 0 2px 35px rgba(0,0,0,.06);
                padding: 30px 30px 25px;
                position: relative;
                text-align: center;
                width: 100%;
                background-color: var(--oksijen-turuncu);
                .our-feature-icon {
                    font-size: 1.5rem;
                    background: var(--oksijen-body);
                    color: var(--oksijen-yesil);
                    border-radius: 50px;
                    border: 2px solid var(--oksijen-body);
                    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.5rem;
                }
                span {
                    font-size: 1.2rem;
                    display: block;
                    font-weight: 700;
                    color: rgba(17, 17, 17, 1);
                }
            }
        }
    }
}
